.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Default padding */
.text-container {
  padding: 0; /* No padding by default */
}

/* Add padding on larger screens */
@media only screen and (min-width: 768px) {
  .text-container {
      padding: 0 170px; /* Adjust padding as needed */
  }
}

.video-container {
  position: relative;
  padding-bottom: 40%; /* Adjust the percentage as needed */
  height: 0;
  overflow: hidden;
  max-width: 100%; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the video container */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Add margin on larger screens */
@media only screen and (min-width: 768px) {
  .video-container {
      margin: 100px; /* Add margin all around the video */
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.imageOfMe {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 25px;
}
